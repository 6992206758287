import React from 'react';

import { CloseCircleTwoTone } from '@ant-design/icons';
import Vimeo from '@u-wave/react-vimeo';
import { Modal } from 'antd';
import Youtube from 'react-youtube';
import ytdl from 'ytdl-core';

export const PopupVideoPlayer = ({
    visible,
    video,
    stopVideo,
    loading,
}: {
    visible: boolean;
    video?: Modules.VideoModuleVideo;
    stopVideo: () => void;
    loading?: boolean;
}) => {
    return (
        <Modal
            open={visible}
            onCancel={stopVideo}
            title={null}
            footer={null}
            closeIcon={<CloseCircleTwoTone style={{ fontSize: '32px' }} />}
            bodyStyle={{ padding: '5px' }}
            width={1024}
            destroyOnClose={true}
        >
            {video?.source === 'vimeo' ? (
                <Vimeo
                    video={video?.info?.id}
                    style={{ width: '100%', padding: 0 }}
                    responsive={true}
                    autoplay={true}
                />
            ) : video?.source === 'youtube' ? (
                <Youtube
                    containerClassName="yt-video-container-max-width"
                    className="yt-video-max-width"
                    videoId={ytdl.getVideoID(video?.link!)}
                    opts={{
                        width: '100%',
                        playerVars: {
                            // https://developers.google.com/youtube/player_parameters
                            autoplay: 1,
                        },
                    }}
                />
            ) : (
                <></>
            )}
        </Modal>
    );
};
