import React, { useContext, useEffect, useState } from 'react';

import {
    CloseOutlined,
    DeleteFilled,
    DeleteOutlined,
    EditFilled,
    EditOutlined,
    SaveFilled,
    SaveOutlined,
    UploadOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {
    Modal,
    Form,
    Collapse,
    Input,
    Space,
    Avatar,
    Upload,
    Button,
    Select,
    Checkbox,
    Tag,
    Typography,
    Divider,
    Row,
    Col,
    TreeSelect,
    FormInstance,
} from 'antd';
import CountryPhoneInput, { ConfigProvider } from 'antd-country-phone-input';
import TextArea from 'antd/lib/input/TextArea';
import { Country, State, City } from 'country-state-city';
import randomString from 'randomstring';
import LocalizedStrings from 'react-localization';
import SortableTree, { getTreeFromFlatData, TreeItem } from 'react-sortable-tree';
import store from 'store';
import en from 'world_countries_lists/data/countries/en/world.json';
import './SiemensProfileEditor.css';

import { GlobalContext } from '../context/GlobalContextProvider';
import useProfile from '../hooks/useProfile';
import { apiRequester, handleError, handleSuccess } from '../utility';

const strings = new LocalizedStrings({
    en: {
        modalTitle: 'Edit your profile',
        close: 'Close',
        save: 'Save',
        enterEmail: 'Please enter user email',
        enterFirstName: 'Please enter user first name',
        enterLastName: 'Please enter user last name',
        enterMobile: 'Please enter user mobile number',
        enterCompany: 'Please company name',
        enterDesignation: 'Please enter designation',
        enterPass: 'Please enter user password',
        enterRole: 'Please select role',
        picUpdated: 'Profile picture has been updated',
        picRemoved: 'Profile picture has been removed',
        picUploaded: 'Profile picture has been uploaded',
        remove: 'Remove',
        update: 'Update',
        updates: 'Receive Updates',
        emailVerified: 'Email Verified',
        tnc: 'TnC Accepted',
        completeField: 'Please complete this field',
        clickUpload: 'Click to upload',
        profileUpdated: 'Your profile has been updated',
    },
    de: {
        modalTitle: 'Bearbeite dein Profil',
        close: 'schließen',
        save: 'Speichern',
        enterEmail: 'Bitte geben Sie die E-Mail-Adresse des Benutzers ein',
        enterFirstName: 'Bitte geben Sie den Vornamen des Benutzers ein',
        enterLastName: 'Bitte geben Sie den Nachnamen des Benutzers ein',
        enterMobile: 'Bitte geben Sie die Handynummer des Benutzers ein',
        enterCompany: 'Bitte Firmenname',
        enterDesignation: 'Bitte Bezeichnung eingeben',
        enterPass: 'Bitte Benutzerpasswort eingeben',
        enterRole: 'Bitte Rolle auswählen',
        picUpdated: 'Profilbild wurde aktualisiert',
        picRemoved: 'Profilbild wurde entfernt',
        picUploaded: 'Profilbild wurde hochgeladen',
        remove: 'Entfernen',
        update: 'Aktualisieren',
        updates: 'Updates erhalten',
        emailVerified: 'Email überprüft',
        tnc: 'TnC akzeptiert',
        completeField: 'Bitte füllen Sie dieses Feld aus',
        clickUpload: 'Zum Hochladen klicken',
        profileUpdated: 'Ihr Profil wurde aktualisiert',
    },
});

const { SHOW_PARENT } = TreeSelect;
const DARK_THEME = process.env.GATSBY_STYLE_THEME === 'dark' ? true : false;
const GATSBY_CLIENT_NAME = process.env.GATSBY_CLIENT_NAME || '';
process.env.GATSBY_STYLE_SHOW_PUBLIC_LOGIN_FORM && process.env.GATSBY_STYLE_SHOW_PUBLIC_LOGIN_FORM === 'true'
    ? true
    : false;

const getFieldAnswer = (answers: string[]) =>
    answers?.map((answer: string, index: number) => {
        return <Tag key={`answer-${index}`}>{answer}</Tag>;
    });

const getTextColor = () => {
    return !DARK_THEME ? 'white' : undefined;
};

const getLabel = (label: string) => {
    return (
        <span
            style={{
                color: getTextColor(),
            }}
        >
            {label}
        </span>
    );
};

const normFile = (e: any) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
        return e;
    }
    return e && e.fileList;
};

const generateTreeData = (nodes: any, keyValueSame = false): any => {
    const result = [];
    const nodeCount = nodes.length;

    for (let i = 0; i < nodeCount; i++) {
        const node = nodes[i];
        const random = randomString.generate(3);

        if (node.type !== 'adder') {
            result.push({
                title: node.value || node.title || node.name,
                value:
                    node.children && node.children.length > 1
                        ? (node.value || node.title || node.name) + '---' + random
                        : node.value || node.title || node.name,
                checkable: node.children && node.children.length > 1 ? false : true,
                selectable: node.children && node.children.length > 1 ? false : true,
                children: generateTreeData(node.children || []),
            });
        }
    }

    return result;
};

const generateTreeProps = (optionTree: any, keyValueSame = false) => {
    const tProps = {
        treeLine: { treeLine: true && { showLeafIcon: false } },
        treeData: generateTreeData(optionTree, keyValueSame),
        treeCheckable: true,
        showCheckedStrategy: SHOW_PARENT,
        style: {
            width: '100%',
        },
    };
    return tProps;
};

const generateTreePropsFromBoothTree = (optionTree: any) => {
    const boothTree = getTreeFromFlatData({
        flatData: (optionTree || []).map((node: TreeItem) => ({
            ...node,
            title: node.name,
            expanded: true,
            key: node.key,
        })),
        getKey: (node: TreeItem) => node.id,
        getParentKey: (node: TreeItem) => node.parent,
        rootKey: -1,
    });
    return generateTreeProps(boothTree || []);
};

export const SiemensProfileEditor = ({ editForm }: { editForm: FormInstance<any> }) => {
    const { refreshProfile, profile, setLoading, refreshExtraFields, extraFields } = useProfile();
    const context = useContext(GlobalContext);

    const fileProps = {
        name: 'file',
        onChange({ file, fileList }: { file?: any; fileList: any }) {
            if (file.status === 'done') {
                setLoading(false);
                handleSuccess(strings.picUpdated!);
                editForm.setFieldsValue({ profilePicture: file.response });
            } else if (file.status === 'uploading') {
                setLoading(true);
                return;
            } else if (file.status === 'error') setLoading(false);
        },
        headers: { Authorization: 'Bearer ' + store.get('token') },
    };

    useEffect(() => {
        extraFields.forEach(field => {
            field.answers = profile?.extras?.find(response => response.question === field.question)?.answers;
            field.values = profile?.extras?.find(response => response.question === field.question)?.answers;
        });

        const profileWithQuestions = {
            ...profile,
            extras: extraFields,
        };

        editForm.setFieldsValue(profileWithQuestions);
    }, [profile, extraFields]);

    useEffect(() => {
        refreshProfile();
        refreshExtraFields();
    }, []);

    const removeProfilePicture = async () => {
        try {
            setLoading(true);
            apiRequester
                .deleteProfilePicture(context.eventAuthModule?._id!, editForm.getFieldValue('_id'))
                .catch(handleError);
            handleSuccess(strings.picRemoved!);
            editForm.setFieldsValue({ profilePicture: undefined });
            await refreshProfile();
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Form form={editForm} layout="vertical">
            <Form.Item label="ID" name="_id" rules={[{ required: true }]} hidden={true}>
                <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Event ID" name="event" hidden={true}>
                <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Client ID" name="client" hidden={true}>
                <Input disabled={true} />
            </Form.Item>
            <Row gutter={8} style={{ width: '100%' }}>
                <Col span={12}>
                    <Form.Item
                        label={getLabel(strings.enterFirstName)}
                        labelCol={{ span: 24 }}
                        name="firstName"
                        rules={[{ required: true, type: 'string', message: strings.enterFirstName }]}
                        validateStatus={!['siemens'].includes(GATSBY_CLIENT_NAME) ? 'warning' : undefined}
                    >
                        <Input
                            style={{
                                background: 'transparent',
                                borderRadius: '20px',
                                color: '#01A4B4',
                                borderColor: '#01A4B4',
                                borderWidth: '2px',
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={getLabel(strings.enterLastName)}
                        labelCol={{ span: 24 }}
                        name="lastName"
                        rules={[{ required: true, type: 'string', message: strings.enterLastName }]}
                        validateStatus={!['siemens'].includes(GATSBY_CLIENT_NAME) ? 'warning' : undefined}
                    >
                        <Input
                            style={{
                                background: 'transparent',
                                borderRadius: '20px',
                                color: '#01A4B4',
                                borderColor: '#01A4B4',
                                borderWidth: '2px',
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={getLabel(strings.enterEmail)}
                        labelCol={{ span: 24 }}
                        name="emailId"
                        rules={[{ required: true, type: 'email', message: strings.enterEmail }]}
                        validateStatus={!['siemens'].includes(GATSBY_CLIENT_NAME) ? 'warning' : undefined}
                    >
                        <Input
                            style={{
                                background: 'transparent',
                                borderRadius: '20px',
                                color: '#01A4B4',
                                borderColor: '#01A4B4',
                                borderWidth: '2px',
                            }}
                        />
                    </Form.Item>
                </Col>
                <Form.List name="extras" key="extras">
                    {fields => (
                        <>
                            {fields?.map(field => {
                                const fieldType = editForm.getFieldValue('extras')[field.name].type;
                                const isCountryCityType = fieldType === 'country-city';

                                if (isCountryCityType) {
                                    return (
                                        <ConfigProvider locale={en}>
                                            <Form.Item noStyle shouldUpdate key={`${field.key}-container`}>
                                                {() => (
                                                    <Row gutter={8} style={{ width: '100%' }}>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                name={[field.name, 'values', 'country']}
                                                                label={getLabel('Country')}
                                                                labelCol={{ span: 24 }}
                                                                rules={[
                                                                    {
                                                                        required: editForm.getFieldValue('extras')[
                                                                            field.name
                                                                        ].required,
                                                                        message: strings.completeField,
                                                                    },
                                                                ]}
                                                            >
                                                                <Select showSearch>
                                                                    {Country.getAllCountries().map(country => (
                                                                        <Select.Option
                                                                            value={`${country.isoCode} - ${country.name}`}
                                                                            key={country.isoCode}
                                                                        >
                                                                            {country.flag} {country.name}
                                                                        </Select.Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                shouldUpdate
                                                                name={[field.name, 'values', 'city']}
                                                                label={getLabel('City')}
                                                                labelCol={{ span: 24 }}
                                                                rules={[
                                                                    {
                                                                        required: editForm.getFieldValue('extras')[
                                                                            field.name
                                                                        ].required,
                                                                        message: strings.completeField,
                                                                    },
                                                                ]}
                                                            >
                                                                <Select showSearch>
                                                                    {(editForm.getFieldValue('extras')[field.key]
                                                                        ?.values?.country
                                                                        ? City.getCitiesOfCountry(
                                                                              editForm
                                                                                  .getFieldValue('extras')
                                                                                  [field.key]?.values?.country?.split(
                                                                                      ' - ',
                                                                                  )[0],
                                                                          )!
                                                                        : []
                                                                    ).map(city => (
                                                                        <Select.Option
                                                                            value={`${city.name}`}
                                                                            key={city.name}
                                                                        >
                                                                            {city.name}
                                                                        </Select.Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </Form.Item>
                                        </ConfigProvider>
                                    );
                                } else {
                                    return (
                                        <ConfigProvider locale={en}>
                                            <Form.Item noStyle shouldUpdate key={`${field.key}-container`}>
                                                {({ getFieldValue }) => {
                                                    return (
                                                        <Col
                                                            span={
                                                                GATSBY_CLIENT_NAME !== 'siemens'
                                                                    ? 24
                                                                    : getFieldValue('extras')[field.name].type ===
                                                                          'checkbox' ||
                                                                      getFieldValue('extras')[field.name].type ===
                                                                          'country-city'
                                                                    ? 24
                                                                    : 12
                                                            }
                                                        >
                                                            <Form.Item
                                                                {...field}
                                                                label={
                                                                    getFieldValue('extras')[field.name].type !==
                                                                        'checkbox' &&
                                                                    getFieldValue('extras')[field.name].type !==
                                                                        'country-city'
                                                                        ? getLabel(
                                                                              getFieldValue('extras')[field.key]
                                                                                  .question,
                                                                          )
                                                                        : undefined
                                                                }
                                                                key={getFieldValue('extras')[field.key]._id}
                                                                name={[field.name, 'values']}
                                                                labelCol={{ span: 24 }}
                                                                valuePropName={
                                                                    getFieldValue('extras')[field.name].type ===
                                                                    'picture'
                                                                        ? 'fileList'
                                                                        : getFieldValue('extras')[field.name].type ===
                                                                          'checkbox'
                                                                        ? 'checked'
                                                                        : undefined
                                                                }
                                                                getValueFromEvent={
                                                                    getFieldValue('extras')[field.name].type ===
                                                                    'picture'
                                                                        ? normFile
                                                                        : undefined
                                                                }
                                                                rules={[
                                                                    {
                                                                        required: getFieldValue('extras')[field.name]
                                                                            .required,
                                                                        message: strings.completeField,
                                                                    },
                                                                    () => ({
                                                                        validator(_, value) {
                                                                            if (
                                                                                value === false &&
                                                                                getFieldValue('extras')[field.name]
                                                                                    .required &&
                                                                                getFieldValue('extras')[field.name]
                                                                                    .type === 'checkbox'
                                                                            ) {
                                                                                return Promise.reject(
                                                                                    strings.completeField,
                                                                                );
                                                                            }

                                                                            return Promise.resolve();
                                                                        },
                                                                    }),
                                                                ]}
                                                                initialValue={
                                                                    getFieldValue('extras')[field.name].type === 'phone'
                                                                        ? { short: 'in' }
                                                                        : undefined
                                                                }
                                                            >
                                                                {getFieldValue('extras')[field.name].type ===
                                                                'phone' ? (
                                                                    <CountryPhoneInput
                                                                        size="small"
                                                                        defaultValue={{ short: 'in' }}
                                                                        style={{
                                                                            background: 'transparent',
                                                                            color: '#01A4B4',
                                                                            border: 0,
                                                                        }}
                                                                        className="siemens-phone-input"
                                                                    />
                                                                ) : getFieldValue('extras')[field.name].type ===
                                                                  'input' ? (
                                                                    <Input
                                                                        style={{
                                                                            background: 'transparent',
                                                                            borderRadius: '20px',
                                                                            color: '#01A4B4',
                                                                            borderColor: '#01A4B4',
                                                                            borderWidth: '2px',
                                                                        }}
                                                                    />
                                                                ) : getFieldValue('extras')[field.name].type ===
                                                                  'textarea' ? (
                                                                    <TextArea
                                                                        style={{
                                                                            background: 'transparent',
                                                                            borderRadius: '20px',
                                                                            color: '#01A4B4',
                                                                            borderColor: '#01A4B4',
                                                                            borderWidth: '2px',
                                                                        }}
                                                                    />
                                                                ) : getFieldValue('extras')[field.name].type ===
                                                                  'picture' ? (
                                                                    <Upload
                                                                        {...fileProps}
                                                                        action={apiRequester.getUserExtraProfileFieldFileUploadUrl(
                                                                            {
                                                                                fieldId: getFieldValue('extras')[
                                                                                    field.name
                                                                                ]._id,
                                                                            },
                                                                        )}
                                                                        listType="picture"
                                                                        multiple={false}
                                                                        maxCount={1}
                                                                        onRemove={data => {
                                                                            apiRequester
                                                                                .deleteUserExtraProfileFieldFileUpload(
                                                                                    getFieldValue('extras')[field.name]
                                                                                        ._id,
                                                                                    data?.response?.fileId!,
                                                                                )
                                                                                .catch(handleError);
                                                                        }}
                                                                    >
                                                                        <Button icon={<UploadOutlined />}>
                                                                            {strings.clickUpload}
                                                                        </Button>
                                                                    </Upload>
                                                                ) : getFieldValue('extras')[field.name].type ===
                                                                  'select' ? (
                                                                    // @ts-ignore: Unreachable code error
                                                                    <TreeSelect
                                                                        {...generateTreeProps(
                                                                            getFieldValue('extras')[field.name].data
                                                                                .optionTree,
                                                                            true,
                                                                        )}
                                                                    />
                                                                ) : getFieldValue('extras')[field.name].type ===
                                                                  'checkbox' ? (
                                                                    <span>
                                                                        <Checkbox
                                                                            style={{ paddingRight: '1.5rem' }}
                                                                            checked={
                                                                                getFieldValue('extras')[field.name]
                                                                                    .values
                                                                            }
                                                                        />
                                                                        <span
                                                                            style={{
                                                                                color:
                                                                                    GATSBY_CLIENT_NAME === 'siemens'
                                                                                        ? 'white'
                                                                                        : !DARK_THEME
                                                                                        ? 'white'
                                                                                        : undefined,
                                                                            }}
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: getFieldValue('extras')[
                                                                                    field.name
                                                                                ].question,
                                                                            }}
                                                                        ></span>
                                                                    </span>
                                                                ) : getFieldValue('extras')[field.name].type ===
                                                                  'booth-tree' ? (
                                                                    // @ts-ignore: Unreachable code error
                                                                    <TreeSelect
                                                                        {...generateTreePropsFromBoothTree(
                                                                            getFieldValue('extras')[field.name].data
                                                                                .optionTree,
                                                                        )}
                                                                    />
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </Form.Item>
                                                        </Col>
                                                    );
                                                }}
                                            </Form.Item>
                                        </ConfigProvider>
                                    );
                                }
                            })}
                        </>
                    )}
                </Form.List>
                {GATSBY_CLIENT_NAME === 'siemens' && (
                    <Form.Item labelCol={{ span: 24 }}>
                        <span style={{ color: 'white' }}>
                            By agreeing and clicking on the “Submit” button, I hereby voluntarily consent to provide
                            information that may be deemed as Personal Data under the Information Technology Act, 2000.
                            I read and understood the Privacy Statement as above.
                        </span>
                    </Form.Item>
                )}
            </Row>
        </Form>
    );
};

export default SiemensProfileEditor;
