import { useContext, useEffect, useState } from 'react';

import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError } from '../utility';

const usePopupVideoPlayer = () => {
    const [loading, setLoading] = useState(false);
    const [videoPlayerVisible, setVideoPlayerVisible] = useState(false);
    const [videos, setVideos] = useState<Modules.VideoModuleVideo[]>([]);
    const [popupVideo, setPopupVideo] = useState<Modules.VideoModuleVideo>();
    const [moduleId, setModuleId] = useState<string>();
    const [videoIndex, setVideoIndex] = useState<number>();
    const [videoId, setVideoId] = useState<string>();
    const context = useContext(GlobalContext);

    const refreshModuleVideos = async () => {
        try {
            if (moduleId) {
                setLoading(true);
                const boothId = context.booth?._id;
                if (!boothId) throw new Error('Booth ID is not defined');
                const videos = await apiRequester.getModuleVideos({ moduleId, boothId });
                setVideos(videos);
            }
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const video = videos.find(v => v._id === videoId) || videos[videoIndex! - 1];
        setPopupVideo(video);
    }, [videos, moduleId, videoId, videoIndex]);

    useEffect(() => {
        refreshModuleVideos();
    }, [moduleId]);

    const hideVideoPlayer = () => {
        setVideoPlayerVisible(false);
    };

    const playVideo = ({
        moduleId,
        videoId,
        videoIndex,
    }: {
        moduleId?: string;
        videoId?: string;
        videoIndex?: number;
    }) => {
        setModuleId(moduleId);
        setVideoId(videoId);
        setVideoIndex(videoIndex);
        setVideoPlayerVisible(true);
    };

    const stopVideo = () => {
        setVideoPlayerVisible(false);
    };

    return { videoPlayerVisible, hideVideoPlayer, popupVideo, popVideoPlayerLoading: loading, playVideo, stopVideo };
};

export default usePopupVideoPlayer;
