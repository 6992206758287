import { useContext, useEffect, useState } from 'react';

import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError } from '../utility';

const usePopupSocialLinks = () => {
    const context = useContext(GlobalContext);
    const [showPopup, setShowPopup] = useState(false);
    const [socialLinks, setSocialLinks] = useState<string[]>([]);

    const hidePopup = () => {
        setShowPopup(false);
    };

    const showSocialLinksPopup = () => {
        setShowPopup(true);
    };

    useEffect(() => {
        const socialLinks = context.booth?.socialLinks || [];
        setSocialLinks(socialLinks);
    }, [context.booth]);

    return {
        socialLinks,
        showSocialLinksPopup,
        socialLinksPopupVisible: showPopup,
        hideSocialLinksPopup: hidePopup,
    };
};

export default usePopupSocialLinks;
