import React from 'react';

import { Helmet } from 'react-helmet';

const GATSBY_CLIENT_NAME = process.env.GATSBY_CLIENT_NAME;

export const ClientHelmet = () => {
    return (
        <>
            <Helmet>
                {GATSBY_CLIENT_NAME === 'siemens' && <title>Siemens Event</title>}

                {GATSBY_CLIENT_NAME === 'huethig' && <title>Huethig Event</title>}
                {GATSBY_CLIENT_NAME === 'huethig' && (
                    <script>
                        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-K8P8FSG');`}
                    </script>
                )}
            </Helmet>

            {GATSBY_CLIENT_NAME === 'huethig' && (
                <noscript>
                    <iframe
                        src="https://www.googletagmanager.com/ns.html?id=GTM-K8P8FSG"
                        height="0"
                        width="0"
                        style={{ display: 'none', visibility: 'hidden' }}
                    />
                </noscript>
            )}
        </>
    );
};

export default ClientHelmet;
