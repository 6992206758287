import React, { useContext, useEffect, useState } from 'react';

import { DeleteOutlined, DownloadOutlined, SendOutlined, UserOutlined } from '@ant-design/icons';
import { Badge, Button, Divider, Drawer, List, Popover, Space, Typography, Tooltip } from 'antd';
import LocalizedStrings from 'react-localization';

import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError, handleSuccess } from '../utility';

const GATSBY_STYLE_EVENT_SHOW_FILE_DOWNLOAD_BASKET_ICON =
    !process.env.GATSBY_STYLE_EVENT_SHOW_FILE_DOWNLOAD_BASKET_ICON ||
    process.env.GATSBY_STYLE_EVENT_SHOW_FILE_DOWNLOAD_BASKET_ICON !== 'no'
        ? true
        : false;
const strings = new LocalizedStrings({
    en: {
        filesSentAfterLogout: 'All files will be sent to your email after you logout.',
        downloadBasket: 'Download Basket',
        noData: 'No files in the basket.',
        itemRemoved: 'Item has been removed from your basket',
    },
    de: {
        filesSentAfterLogout: 'Nach dem Logout werden Ihnen alle Dateien per Mail zugesendet.',
        downloadBasket: 'Gesammelte Downloads',
        noData: 'Keine Dateien vorhanden',
        itemRemoved: 'Der Artikel wurde aus Ihrem Warenkorb entfernt',
    },
});

export const DownloadBasket = ({
    showBasket,
    setShowBasket,
}: {
    showBasket: boolean;
    setShowBasket: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const context = useContext(GlobalContext);
    const [items, setItems] = useState<DownloadBasket.Item[]>([]);
    const [loading, setLoading] = useState(false);
    const { eventAuthModule } = context;

    const refreshItems = async () => {
        try {
            setLoading(true);
            const basket = await apiRequester.getDownloadBasket();
            setItems(basket.items);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            throw err;
        }
    };

    useEffect(() => {
        eventAuthModule !== undefined && refreshItems().catch(handleError);
    }, [showBasket]);

    const removeItemFromBasket = ({ itemId }: { itemId: string }) => {
        apiRequester
            .removeItemFromDownloadBasket({ itemId })
            .then(() => handleSuccess(strings.itemRemoved))
            .then(refreshItems)
            .catch(handleError);
    };

    return GATSBY_STYLE_EVENT_SHOW_FILE_DOWNLOAD_BASKET_ICON ? (
        <>
            {/* <div style={{ position: 'fixed', bottom: '3rem', left: '1rem' }}>
                <Button
                    size="small"
                    shape="circle"
                    type="default"
                    icon={<DownloadOutlined />}
                    onClick={() => setShowBasket(true)}
                />
                <span
                    style={{
                        backgroundColor: 'white',
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        borderRadius: 100,
                        marginLeft: '5px',
                    }}
                >
                    Download Basket
                </span>
            </div> */}
            <Drawer
                title={strings.downloadBasket}
                placement="right"
                closable={true}
                onClose={() => setShowBasket(false)}
                open={showBasket}
                key={'left'}
                width={500}
                bodyStyle={{ display: 'flex', flexDirection: 'column' }}
            >
                <List
                    style={{ flexGrow: 1 }}
                    locale={{ emptyText: strings.noData }}
                    dataSource={items}
                    loading={loading}
                    renderItem={(item: DownloadBasket.Item) => (
                        <List.Item
                            extra={
                                <Button
                                    shape="circle"
                                    danger
                                    size="small"
                                    icon={<DeleteOutlined />}
                                    disabled={loading}
                                    onClick={() => removeItemFromBasket({ itemId: item._id! })}
                                />
                            }
                        >
                            <Space direction="vertical" size="small">
                                <Typography.Text strong>
                                    {typeof item.file === 'string'
                                        ? item.file
                                        : item.file?.customName || item.file?.name}
                                </Typography.Text>
                                <Typography.Text type="secondary">
                                    {typeof item.booth === 'string' ? item.booth : item.booth?.name}
                                </Typography.Text>
                            </Space>
                        </List.Item>
                    )}
                ></List>
                <Typography.Paragraph style={{ textAlign: 'center' }} strong>
                    {strings.filesSentAfterLogout}
                </Typography.Paragraph>
            </Drawer>
        </>
    ) : (
        <></>
    );
};

export default DownloadBasket;
