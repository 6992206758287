export enum NOTIFIER_MESSAGE_IDS {
    VIDEO_CALL = 'VIDEO_CALL',
    CHAT = 'CHAT',
    LIVE_STREAMING = 'LIVE_STREAMING',
}

export enum LIVE_STREAM_ACTIONS {
    QUESTION_APPROVED = 'QUESTION_APPROVED',
    NEW_QUESTION = 'NEW_QUESTION',
}

export enum VIDEO_CALL_ACTIONS {
    NEW_REQUEST_FROM_VISITOR_TO_OPERATOR = 'NEW_REQUEST_FROM_VISITOR_TO_OPERATOR',
    OPERATOR_JOINED = 'OPERATOR_JOINED',
    OPERATOR_LEFT = 'OPERATOR_LEFT',
    OPERATOR_REJECTED = 'OPERATOR_REJECTED',
    OPERATOR_ACCEPTED = 'OPERATOR_ACCEPTED',
    VISITOR_ACCEPTED = 'VISITOR_ACCEPTED',
    VISITOR_REJECTED = 'VISITOR_REJECTED',
    VISITOR_ENDED = 'VISITOR_ENDED',
}

export enum CHAT_ACTIONS {
    CHAT_UPDATED = 'CHAT_UPDATED',
    NEW_MESSAGE = 'NEW_MESSAGE',
}

export const generateNotifierMessage = {
    videoRequest: ({
        booth,
        requester,
        action,
    }: {
        booth: {
            id: string;
            name?: string;
        };
        respondent?: {
            id: string;
            firstName?: string;
            lastName?: string;
        };
        requester?: {
            id: string;
            firstName?: string;
            lastName?: string;
        };
        action: VIDEO_CALL_ACTIONS;
    }) => {
        return {
            id: NOTIFIER_MESSAGE_IDS.VIDEO_CALL,
            booth,
            requester,
            action,
        };
    },
    chat: ({
        booth,
        action,
        sender,
    }: {
        booth: {
            id: string;
            name?: string;
        };
        sender: {
            id: string;
            firstName?: string;
            lastName?: string;
        };
        action: CHAT_ACTIONS;
    }) => {
        return {
            id: NOTIFIER_MESSAGE_IDS.CHAT,
            booth,
            sender,
            action,
        };
    },
    liveStreamQuestion: ({
        booth,
        action,
    }: {
        booth?: {
            id: string;
            name?: string;
        };
        action?: LIVE_STREAM_ACTIONS;
    }) => {
        return {
            id: NOTIFIER_MESSAGE_IDS.LIVE_STREAMING,
            booth,
            action,
        };
    },
};
