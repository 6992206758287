import React, { useContext, useEffect, useState } from 'react';

import { RightOutlined, SearchOutlined } from '@ant-design/icons';
import { List, Input, Typography, Button } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import Modal from 'antd/lib/modal/Modal';
import { navigate } from 'gatsby-link';
import Pubnub from 'pubnub';
import { usePubNub } from 'pubnub-react';
import LocalizedStrings from 'react-localization';

import { GlobalContext } from '../context/GlobalContextProvider';
import { useBoothCommunication } from '../hooks';

const IFRAME_NAME = 'main-event-iframe';

const strings = new LocalizedStrings({
    en: {
        manned: 'Manned Booths',
        searchBooths: 'Search for booths by name',
        mannedAdditionalGreen: 'Green button: Stand is occupied.',
        mannedAdditionalRed: 'Red button: The booth is currently unoccupied.',
        visit: 'Visit',
    },
    de: {
        manned: 'Ausstellerliste',
        searchBooths: 'Stände nach Namen suchen',
        mannedAdditionalGreen: 'Grüner Button: Stand ist besetzt.',
        mannedAdditionalRed: 'Roter Button: Stand ist aktuell nicht besetzt.',
        visit: 'Besuch',
    },
});

export const MannedBooths = ({
    showMannedBoothsModal,
    setShowMannedBoothsModal,
}: {
    showMannedBoothsModal: boolean;
    setShowMannedBoothsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const context = useContext(GlobalContext);
    const [onlineBoothIds, setOnlineBoothIds] = useState<string[]>([]);
    const [searchString, setSearchString] = useState<string | undefined>();
    const { hereNow, addChannels, addListener, removeChannels, removeListener } = useBoothCommunication();

    const refreshOnlineBoothIds = async () => {
        const booths = context.booths;
        const boothIds = booths?.map(booth => booth._id);
        const channels = boothIds?.map(boothId => `booth-operators.${boothId}`);
        const hereNowResp = await hereNow({ channels });
        const onlineBoothChannels = hereNowResp.channels;
        const onlineBoothChannelIds = Object.keys(onlineBoothChannels);
        const onlineBoothIds = onlineBoothChannelIds.map(channelId => channelId.split('.')[1]);
        setOnlineBoothIds(onlineBoothIds);
    };

    const presenceHandler = async (presenceEvent: Pubnub.PresenceEvent) => {
        if (presenceEvent.channel.includes('booth-operators')) refreshOnlineBoothIds();
    };

    const notifierMessageListener = {
        presence: presenceHandler,
    };

    useEffect(() => {
        if (showMannedBoothsModal) {
            const booths = context.booths?.filter(booth => booth.showInMannedList);
            const boothIds = booths?.map(booth => booth._id);
            const channels = boothIds?.map(boothId => `booth-operators.${boothId}-pnpres`) || [];
            addListener(notifierMessageListener);
            addChannels(channels);
            refreshOnlineBoothIds();
            return () => {
                removeListener(notifierMessageListener);
            };
        }
    }, [context.booths, showMannedBoothsModal]);

    const getStatusColor = ({ boothId }: { boothId: string }) => {
        const boothIsOnline = context.booths?.find(booth => onlineBoothIds.includes(boothId));
        if (boothIsOnline) return '#2ecc71';
        else return '#e74c3c';
    };

    const filterBooths = (booths: Booths.Booth[] | undefined) => {
        if (!booths) return booths;
        else if (searchString) {
            const filtered = booths.filter(booth => booth.name?.toLowerCase().includes(searchString));
            return filtered;
        } else return booths;
    };

    const navigateToPanorama = (panoramaNameName: string) => {
        const message = `NAVIGATE_TO_PANORAMA____${JSON.stringify(panoramaNameName)}`;
        (document?.getElementById(IFRAME_NAME) as HTMLIFrameElement)?.contentWindow?.postMessage(message, '*');
    };

    return (
        <Modal
            open={showMannedBoothsModal}
            footer={null}
            centered
            onCancel={() => setShowMannedBoothsModal(false)}
            title={
                <>
                    <Typography.Text>{strings.manned}</Typography.Text>
                    <br />
                    <Typography.Text type="secondary">
                        {strings.mannedAdditionalGreen} <br />
                        {strings.mannedAdditionalRed} <br />
                    </Typography.Text>
                </>
            }
            destroyOnClose={true}
        >
            <Input
                size="large"
                placeholder={strings.searchBooths}
                prefix={<SearchOutlined />}
                style={{ marginBottom: '1.5rem' }}
                onChange={e => setSearchString(e.target.value.toLowerCase())}
                allowClear={true}
            />
            <List
                itemLayout="horizontal"
                dataSource={filterBooths(
                    context.booths
                        ?.filter(booth => booth.showInMannedList)
                        .sort((booth1, booth2) => {
                            if (!booth1.name || !booth2.name) return 0;
                            else if (booth1?.name < booth2?.name) return -1;
                            else if (booth1?.name > booth2.name) return 1;
                            else return 0;
                        }),
                )}
                renderItem={item => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={
                                <Avatar style={{ backgroundColor: getStatusColor({ boothId: item._id! }) }}>
                                    {item.name![0]}
                                </Avatar>
                            }
                            title={item.name}
                            description={item.description}
                        />
                        {item.panoramaName && (
                            <Button
                                type="primary"
                                size="small"
                                icon={<RightOutlined />}
                                onClick={() => {
                                    navigateToPanorama(item.panoramaName!);
                                    setShowMannedBoothsModal(false);
                                }}
                            >
                                {strings.visit}
                            </Button>
                        )}
                    </List.Item>
                )}
            />
        </Modal>
    );
};

export default MannedBooths;
