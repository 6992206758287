import React, { useContext, useEffect, useState } from 'react';

import {
    CalendarOutlined,
    ClockCircleOutlined,
    DeleteOutlined,
    DownloadOutlined,
    SendOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Badge, Button, Col, Divider, Drawer, List, Popover, Row, Space, Tooltip, Typography } from 'antd';
import { navigate } from 'gatsby-link';
import moment from 'moment';
import LocalizedStrings from 'react-localization';
import 'moment/locale/de';

import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError, handleSuccess } from '../utility';

if (typeof window !== 'undefined' && window.navigator && window.navigator.language === 'de') {
    moment.locale('de');
}

const GATSBY_STYLE_EVENT_SHOW_BOOKED_SLOTS_ICON =
    !process.env.GATSBY_STYLE_EVENT_SHOW_BOOKED_SLOTS_ICON ||
    process.env.GATSBY_STYLE_EVENT_SHOW_BOOKED_SLOTS_ICON !== 'no'
        ? true
        : false;

const strings = new LocalizedStrings({
    en: {
        scheduledCalls: 'Scheduled Calls',
        booth: 'Booth',
        topic: 'Topic',
        startsAt: 'Starts at',
        endsAt: 'Ends at',
        emptyText: 'No Data',
    },
    de: {
        scheduledCalls: 'Meine Termine',
        booth: 'Stand',
        topic: 'Thema',
        startsAt: 'Beginn',
        endsAt: 'Ende',
        emptyText: 'Keine Dateien vorhanden',
    },
});

export const ScheduledRooms = ({
    showScheduledRoomsPanel,
    setShowScheduledRoomsPanel,
}: {
    showScheduledRoomsPanel: boolean;
    setShowScheduledRoomsPanel: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const context = useContext(GlobalContext);
    const [rooms, setRooms] = useState<Modules.VideoRoom[]>([]);
    const [loading, setLoading] = useState(false);
    const { eventAuthModule } = context;

    const refreshRooms = async () => {
        try {
            setLoading(true);
            const rooms = await apiRequester.getScheduledRooms();
            setRooms(rooms);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            throw err;
        }
    };

    useEffect(() => {
        eventAuthModule !== undefined && refreshRooms().catch(handleError);
    }, [showScheduledRoomsPanel]);

    const navigateToICalURL = async ({
        boothId,
        moduleId,
        roomId,
    }: {
        boothId: string;
        moduleId: string;
        roomId: string;
    }) => {
        const currentUrl = typeof window !== 'undefined' ? window.location.toString() : '';
        const redirectUrl = await apiRequester.getICalUrl({ boothId, moduleId, roomId, url: currentUrl });
        navigate(`${redirectUrl}${typeof window !== 'undefined' ? window.location.search : ''}`);
    };

    return GATSBY_STYLE_EVENT_SHOW_BOOKED_SLOTS_ICON ? (
        <>
            {/* <div style={{ position: 'fixed', bottom: '5rem', left: '1rem' }}>
                <Button
                    size="small"
                    shape="circle"
                    type="default"
                    icon={<ClockCircleOutlined />}
                    onClick={() => setShowScheduledRoomsPanel(true)}
                />
                <span
                    style={{
                        backgroundColor: 'white',
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        borderRadius: 100,
                        marginLeft: '5px',
                    }}
                >
                    Scheduled Calls
                </span>
            </div> */}
            <Drawer
                title={strings.scheduledCalls}
                placement="right"
                closable={true}
                onClose={() => setShowScheduledRoomsPanel(false)}
                open={showScheduledRoomsPanel}
                key={'left'}
                width={500}
                bodyStyle={{ display: 'flex', flexDirection: 'column' }}
            >
                <List
                    locale={{ emptyText: strings.emptyText }}
                    style={{ flexGrow: 1 }}
                    dataSource={rooms}
                    loading={loading}
                    renderItem={(item: Modules.VideoRoom) => (
                        <List.Item
                            extra={
                                <Tooltip title="Add to Calendar" placement="left">
                                    <Button
                                        shape="circle"
                                        type="ghost"
                                        icon={<CalendarOutlined />}
                                        onClick={() =>
                                            navigateToICalURL({
                                                boothId: typeof item.booth === 'string' ? item.booth : item.booth?._id!,
                                                moduleId:
                                                    typeof item.module === 'string' ? item.module : item.module?._id!,
                                                roomId: item._id!,
                                            })
                                        }
                                    />
                                </Tooltip>
                            }
                        >
                            <>
                                <Row style={{ width: '100%' }}>
                                    <Col xs={8}>
                                        <Typography.Text strong>{strings.booth}</Typography.Text>
                                    </Col>
                                    <Col xs={16}>
                                        <Typography.Text>
                                            {typeof item.booth !== 'string' && item?.booth?.name}
                                        </Typography.Text>
                                    </Col>
                                    <Col xs={8}>
                                        <Typography.Text strong>{strings.topic}</Typography.Text>
                                    </Col>
                                    <Col xs={16}>
                                        <Typography.Text>{item?.name}</Typography.Text>
                                    </Col>
                                    <Col xs={8}>
                                        <Typography.Text strong>{strings.startsAt}</Typography.Text>
                                    </Col>
                                    <Col xs={16}>
                                        <Typography.Text>{moment(item?.startTime).format('LLLL')}</Typography.Text>
                                    </Col>
                                    <Col xs={8}>
                                        <Typography.Text strong>{strings.endsAt}</Typography.Text>
                                    </Col>
                                    <Col xs={16}>
                                        <Typography.Text>{moment(item?.endTime).format('LLLL')}</Typography.Text>
                                    </Col>
                                </Row>
                            </>
                        </List.Item>
                    )}
                ></List>
            </Drawer>
        </>
    ) : (
        <></>
    );
};

export default ScheduledRooms;
