import React, { useContext, useEffect, useState } from 'react';

import { CloseCircleFilled, CloseCircleOutlined, EyeOutlined, PlayCircleOutlined } from '@ant-design/icons';
import Vimeo from '@u-wave/react-vimeo';
import { Col, Row, Typography, Modal, List, Space, Button, Spin } from 'antd';
import Youtube from 'react-youtube';
import ytdl from 'ytdl-core';

import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError } from '../utility';
import './LiteBoothModal.css';

export const LiteBoothModal = ({ visible, onCancel }: { visible: boolean; onCancel: () => Promise<void> }) => {
    const context = useContext(GlobalContext);
    const boothName = context.booths?.filter(booth => booth._id === context.booth?._id).length
        ? context.booths?.filter(booth => booth._id === context.booth?._id)[0].name
        : '';
    const [media, setMedia] = useState({
        files: [] as Modules.File[],
        videos: [] as (Modules.VideoModuleVideo & { info?: ytdl.videoInfo })[],
    });
    const [activeVideo, setActiveVideo] = useState<Modules.VideoModuleVideo>();
    const [activeFile, setActiveFile] = useState<Modules.File>();
    const [loading, setLoading] = useState(false);

    const loadFilesAndVideos = async () => {
        try {
            setLoading(true);
            const boothId = context.booth?._id;
            const videoModules = context.boothModules?.filter(module => ['video'].includes(module?.type!));
            const fileDownloadModules = context.boothModules?.filter(module =>
                ['file-downloads'].includes(module?.type!),
            );

            let fileRequests = [] as Array<Promise<Modules.File[]>>;
            let videoRequests = [] as Array<Promise<Modules.VideoModuleVideo[]>>;

            // Get all files
            if (fileDownloadModules?.length) {
                fileRequests = fileDownloadModules?.map(module =>
                    apiRequester.getFiles({ boothId: boothId!, moduleId: module?._id! }),
                );
            }

            // Get all videos
            if (videoModules?.length)
                videoRequests = videoModules?.map(module =>
                    apiRequester.getModuleVideos({ boothId: boothId!, moduleId: module?._id! }),
                );

            const [allFilesResponses, allVideosResponses] = await Promise.all([
                Promise.all(fileRequests),
                Promise.all(videoRequests),
            ]);

            const allFiles: Modules.File[] = [];
            allFilesResponses.forEach(files => allFiles.push(...files));

            const allVideos: Modules.VideoModuleVideo[] = [];
            allVideosResponses.forEach(videos => {
                allVideos.push(...videos);
                if (videos && videos.length) setActiveVideo(videos[0]);
            });

            setMedia({ files: allFiles, videos: allVideos });
            allVideos && allVideos.length
                ? setActiveVideo(allVideos[0])
                : allFiles && allFiles.length
                ? setActiveFile(allFiles[0])
                : null;
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadFilesAndVideos();
    }, [context.boothModules]);

    useEffect(() => {
        return () => {
            setMedia({ files: [], videos: [] });
            setActiveFile(undefined);
            setActiveVideo(undefined);
        };
    }, []);

    const viewContent = ({
        type,
        content,
    }: {
        type: 'video' | 'file';
        content: Modules.File | Modules.VideoModuleVideo;
    }) => {
        if (type === 'video') {
            setActiveFile(undefined);
            setActiveVideo(content);
        } else {
            setActiveVideo(undefined);
            setActiveFile(content);
        }
    };

    const getFileThumbnail = (file: Modules.File) => {
        const thumbnailLink = file.thumbnail?.link;
        if (thumbnailLink) return <img src={thumbnailLink} height={64} />;
        else {
            const fileName = file.name;
            const extension = fileName?.split('.').pop();
            if (extension?.toLocaleLowerCase() === 'pdf')
                return <img style={{ borderRadius: '5px' }} height={64} src="/pdf.png" />;
            else return <img style={{ borderRadius: '5px' }} height={64} src="/file.png" />;
        }
    };

    return (
        <Modal
            open={visible}
            onCancel={onCancel}
            width="100vw"
            centered
            bodyStyle={{ height: '86vh', backgroundColor: '#000128', border: '2px solid #01A4B4', borderRadius: '5px' }}
            mask={false}
            maskClosable={false}
            keyboard={false}
            footer={null}
            closable={true}
            style={{
                padding: 0,
                maxWidth: '100vw',
                height: '100%',
            }}
            closeIcon={<CloseCircleFilled style={{ fontSize: '1.5rem', color: '#01A4B4' }} />}
            destroyOnClose={true}
            afterClose={() => {
                setActiveFile(undefined);
                setActiveVideo(undefined);
            }}
        >
            <Row gutter={[24, 24]} style={{ height: '100%' }}>
                <Col xs={18} style={{ height: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                    <Typography.Title level={3} style={{ color: 'white' }}>
                        {boothName && `You're currently at ${boothName}`}
                    </Typography.Title>
                    <div style={{ flexGrow: 1 }}>
                        {activeVideo && activeVideo.source === 'youtube' && (
                            <Youtube
                                containerClassName="yt-video-container"
                                className="yt-video"
                                videoId={ytdl.getVideoID(activeVideo?.link!)}
                                opts={{
                                    height: '100%',
                                    width: '100%',
                                    playerVars: {
                                        // https://developers.google.com/youtube/player_parameters
                                        autoplay: 1,
                                    },
                                }}
                            />
                        )}
                        {activeVideo && activeVideo.source === 'vimeo' && (
                            <Vimeo
                                className="yt-video"
                                video={activeVideo?.info?.id}
                                style={{ height: '100%', width: '100%', padding: 0 }}
                                responsive={true}
                                autoplay={true}
                            />
                        )}
                        {activeFile && !['ppt', 'docx', 'doc'].includes(activeFile.link?.split('.').pop()!) && (
                            <iframe src={activeFile.link} style={{ border: 0, height: '100%', width: '100%' }} />
                        )}
                        {activeFile && ['ppt', 'docx', 'doc'].includes(activeFile.link?.split('.').pop()!) && (
                            <iframe
                                src={`https://view.officeapps.live.com/op/embed.aspx?src=${activeFile.link}`}
                                style={{ border: 0, height: '100%', width: '100%' }}
                            />
                        )}
                    </div>
                </Col>
                <Col
                    xs={6}
                    style={{
                        height: '100%',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography.Title level={5} style={{ color: '#01A4B4' }}>
                        Videos
                    </Typography.Title>
                    <List
                        dataSource={media.videos}
                        renderItem={video => {
                            return (
                                <Row gutter={[24, 24]}>
                                    <Col xs={12}>
                                        <img
                                            style={{ borderRadius: '5px' }}
                                            src={
                                                video.source === 'youtube'
                                                    ? video.info?.thumbnails[0].url
                                                    : video.info?.thumbnail_large
                                            }
                                            width="100%"
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <Space direction="vertical" style={{ width: '100%' }}>
                                            <Typography.Text strong style={{ color: 'white' }}>
                                                {video.info?.title}
                                            </Typography.Text>
                                            <Button
                                                size="small"
                                                type="primary"
                                                icon={<PlayCircleOutlined />}
                                                onClick={() => viewContent({ type: 'video', content: video })}
                                            >
                                                Play
                                            </Button>
                                        </Space>
                                    </Col>
                                </Row>
                            );
                        }}
                    />

                    <Typography.Title level={5} style={{ color: '#01A4B4' }}>
                        Files
                    </Typography.Title>
                    <List
                        dataSource={media.files}
                        renderItem={file => {
                            return (
                                <Row gutter={[24, 24]}>
                                    <Col xs={12} style={{ textAlign: 'center' }}>
                                        {getFileThumbnail(file)}
                                    </Col>
                                    <Col xs={12}>
                                        <Space direction="vertical" style={{ width: '100%' }}>
                                            <Typography.Text strong style={{ color: 'white' }}>
                                                {file.name}
                                            </Typography.Text>
                                            <Button
                                                size="small"
                                                type="primary"
                                                icon={<EyeOutlined />}
                                                onClick={() => viewContent({ type: 'file', content: file })}
                                            >
                                                View
                                            </Button>
                                        </Space>
                                    </Col>
                                </Row>
                            );
                        }}
                    />
                </Col>
            </Row>
        </Modal>
    );
};

export default LiteBoothModal;
