import React, { useContext, useEffect, useMemo, useState } from 'react';

import { InfoCircleOutlined, InfoOutlined } from '@ant-design/icons';
import { Divider, List, Form, Input, Button, Tag, Tooltip } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import { usePubNub } from 'pubnub-react';
import LocalizedStrings from 'react-localization';

import { GlobalContext } from '../context/GlobalContextProvider';
import { useBoothCommunication } from '../hooks';
import { apiRequester, handleError, handleSuccess } from '../utility';
import { generateNotifierMessage, LIVE_STREAM_ACTIONS } from '../utility/NotifierMessages';

const strings = new LocalizedStrings({
    en: {
        yourQuestion: 'Your Question',
        inputQuestion: 'Please input your question',
        questionSentForApproval: 'Your question has been sent for approval',
        ask: 'Ask',
        noData: 'No questions',
        askedBy: 'asked by',
        approved: 'Approved',
        rejected: 'Rejected',
        sentForApproval: 'Sent for approval',
    },
    de: {
        yourQuestion: 'Ihre Frage',
        inputQuestion: 'Bitte geben Sie Ihre Frage ein',
        questionSentForApproval: 'Ihre Frage wurde zur Genehmigung gesendet',
        ask: 'Absenden',
        noData: 'Keine Daten',
        askedBy: 'gefragt von',
        approved: 'Zugelassen',
        rejected: 'Abgelehnt',
        sentForApproval: 'Zur Genehmigung gesendet',
    },
});

export const LiveStreamingQuestions = ({
    showLiveStreamQuestionsModal,
    setShowLiveStreamQuestionsModal,
    refresh,
}: {
    showLiveStreamQuestionsModal: boolean;
    setShowLiveStreamQuestionsModal: React.Dispatch<React.SetStateAction<boolean>>;
    refresh?: string;
}) => {
    const [loading, setLoading] = useState(false);
    const [questions, setQuestions] = useState<Modules.LiveStreamQuestion[]>([]);
    const context = useContext(GlobalContext);
    const user = context.user;
    const userId = typeof user === 'string' ? user : user?._id;
    const { publish } = useBoothCommunication();
    const [addQuestionForm] = Form.useForm();

    const refreshQuestions = async () => {
        if (context.liveStreamingModules && context.liveStreamingModules.length) {
            const questions = await apiRequester.getLiveStreamQuestions({
                boothId: context.booth?._id!,
                moduleId: context.liveStreamingModules[0]?._id!,
            });
            const ownAndApprovedQuestions = questions
                .filter(
                    question =>
                        (typeof question.askedBy === 'string' && question.askedBy === userId) ||
                        (typeof question.askedBy !== 'string' && question.askedBy?._id === userId) ||
                        question.status === 'approved',
                )
                .reverse();
            setQuestions(ownAndApprovedQuestions);
        }
    };

    useEffect(() => {
        const liveStreamingModules = context.liveStreamingModules;
        if (liveStreamingModules && liveStreamingModules.length) {
            setLoading(true);
            refreshQuestions()
                .catch(handleError)
                .finally(() => setLoading(false));
        }
    }, [context.liveStreamingModules, showLiveStreamQuestionsModal, refresh]);

    const toggleModalVisibility = () => {
        if (showLiveStreamQuestionsModal) setShowLiveStreamQuestionsModal(false);
        else setShowLiveStreamQuestionsModal(true);
    };

    const generateDescription = (question: Modules.LiveStreamQuestion) => {
        const name =
            typeof question.askedBy === 'string'
                ? question.askedBy
                : question.askedBy?.firstName + ' ' + question.askedBy?.lastName;
        const askedAt = moment(question.createdAt).fromNow();
        return `${strings.askedBy} ${name} ${askedAt}`;
    };

    const getQuestionTag = (question: Modules.LiveStreamQuestion) => {
        if (question.status === 'approved') return <Tag color="green">{strings.approved}</Tag>;
        else if (question.status === 'rejected')
            return (
                <Tooltip title={question.rejectReason}>
                    <Tag icon={<InfoCircleOutlined />} color="red">
                        {strings.rejected}
                    </Tag>
                </Tooltip>
            );
        else return <Tag color="blue">{strings.sentForApproval}</Tag>;
    };

    const QuestionList = () => {
        return (
            <List
                loading={loading}
                locale={{ emptyText: strings.noData }}
                size="small"
                dataSource={questions}
                renderItem={item => (
                    <List.Item extra={getQuestionTag(item)}>
                        <List.Item.Meta description={generateDescription(item)} title={item.question} />
                    </List.Item>
                )}
            />
        );
    };

    const sendAskQuestionNotification = async () => {
        await publish({
            channel: `booth-operators.${context.booth?._id!}`,
            message: generateNotifierMessage.liveStreamQuestion({
                booth: {
                    id: context.booth?._id!,
                },
                action: LIVE_STREAM_ACTIONS.NEW_QUESTION,
            }),
        });
    };

    const askQuestion = async (form: { [key: string]: string }) => {
        try {
            setLoading(true);
            const question = form.question;
            if (!context.liveStreamingModules || !context.liveStreamingModules.length)
                throw new Error('No live streaming modules found for this booth.');
            await apiRequester.askQuestion({
                boothId: context.booth?._id!,
                moduleId: context.liveStreamingModules[0]?._id!,
                question: question!,
            });
            handleSuccess(strings.questionSentForApproval!);
            await refreshQuestions();
            await sendAskQuestionNotification();
            addQuestionForm.resetFields();
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const AddQuestionForm = () =>
        useMemo(() => {
            return (
                <Form
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    onFinish={askQuestion}
                    form={addQuestionForm}
                >
                    <Form.Item
                        label={strings.yourQuestion}
                        name="question"
                        rules={[{ required: true, message: strings.inputQuestion }]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block loading={loading}>
                            {strings.ask}
                        </Button>
                    </Form.Item>
                </Form>
            );
        }, []);

    return (
        <Modal
            open={showLiveStreamQuestionsModal}
            onCancel={toggleModalVisibility}
            destroyOnClose={true}
            footer={false}
        >
            <AddQuestionForm />
            <Divider />
            <QuestionList />
        </Modal>
    );
};

export default LiveStreamingQuestions;
