import React, { useContext, useEffect, useState } from 'react';

import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError } from '../utility';

export const useProfile = () => {
    const context = useContext(GlobalContext);
    const loggedInUser = context.user;
    const [profile, setProfile] = useState<Users.User>();
    const [loading, setLoading] = useState(false);
    const [extraFields, setExtraFields] = useState<Users.ExtraField[]>([]);

    const refreshProfile = async () => {
        try {
            if (loggedInUser?._id) {
                setLoading(true);
                const userId = loggedInUser?._id!;
                const profile = await apiRequester.getProfile({ userId });
                setProfile(profile);
            } else {
                console.log('User not logged in');
            }
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const refreshExtraFields = async () => {
        try {
            setLoading(true);
            const fields = await apiRequester.getUserExtraProfileFields();
            setExtraFields(fields);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const toggleProfileEditor = () => {
        context.setShowProfileEditor(showProfile => !showProfile);
    };

    return {
        toggleProfileEditor,
        showProfileEditor: context.showProfileEditor,
        loading,
        profile,
        extraFields,
        refreshProfile,
        refreshExtraFields,
        setLoading,
    };
};

export default useProfile;
