import React from 'react';

import { Button } from 'antd';

const GATSBY_CLIENT_NAME = process.env.GATSBY_CLIENT_NAME;

export const PrivacyPolicy = () => {
    return GATSBY_CLIENT_NAME === 'siemens' ? (
        <Button type="link" href="https://new.siemens.com/in/en/general/legal/privacy-notice.html" target="_blank">
            Privacy Notice
        </Button>
    ) : (
        <></>
    );
};

export default PrivacyPolicy;
