import React, { ReactNode } from 'react';

import { Layout as AntdLayout } from 'antd';

const { Content } = AntdLayout;

export const Layout = ({ children }: { children: ReactNode }) => {
    return (
        <AntdLayout className="layout" style={{ minHeight: '100vh' }}>
            <Content style={{ flexGrow: 1 }}>{children}</Content>
        </AntdLayout>
    );
};

export default Layout;
